/**
 * @module 路由组件映射文件
 */
import { lazy } from 'react'
import Kidnap from '@/components/system/routerTab/utils'

let routers: IRouterItem[] = [
	{
		path: '1',
		redirect: '1',
		component: '1',
		name: '父级路由专用(默认)',
	},
	// 首页
	{
		path: '/home/home',
		redirect: '/home/home',
		component: '/Index',
		name: '工作台首页',
		Component: lazy(() => import('@/pages/Index')),
	},
	// 老板工作台
	{
		path: '/BossWorkIndex',
		redirect: '/BossWorkIndex',
		component: '/BossWorkIndex',
		name: '管理员首页',
		Component: lazy(() => import('@/pages/BossWorkIndex')),
	},
	{
		path: '/analysis',
		redirect: '/analysis',
		component: '/dataAnalysis',
		name: '数据分析',
		Component: lazy(() => import('@/pages/dataAnalysis')),
	},
	{
		path: '/SeriesDataset',
		redirect: '/SeriesDataset',
		component: '/SeriesDataset',
		name: '商务数据统计',
		Component: lazy(() => import('@/pages/SeriesDataset')),
	},
	{
		path: '/Perfect',
		redirect: '/Perfect',
		component: '/Perfect',
		name: '完善资料',
		Component: lazy(() => import('@/pages/Perfect')),
	},
	{
		path: '/Settlement',
		redirect: '/Settlement',
		component: '/Settlement',
		name: '结算审核',
		Component: lazy(() => import('@/pages/Settlement')),
	},
	{
		path: '/Operation',
		redirect: '/Operation',
		component: '/Operation',
		name: '运营审核',
		Component: lazy(() => import('@/pages/Operation')),
	},
	{
		path: '/ContactAudit',
		redirect: '/ContactAudit',
		component: '/ContactAudit',
		name: '联系方式审核',
		Component: lazy(() => import('@/pages/ContactAudit')),
	},
	// 积分
	{
		path: '/Integral/detail',
		redirect: '/Integral/detail',
		component: '/Integral/detail',
		name: '积分明细',
		Component: lazy(() => import('@/pages/Integral/detail')),
	},
	// mcn
	{
		path: '/Mcn/apply',
		redirect: '/Mcn/apply',
		component: '/Mcn/apply',
		name: '处理MCN关联申请',
		Component: lazy(() => import('@/pages/Mcn/apply')),
	},
	// TODO 财务
	{
		path: '/Financial/apply',
		redirect: '/Financial/apply',
		component: '/Financial/apply',
		name: '财务申请',
		Component: lazy(() => import('@/pages/Financial/apply')),
	},
	{
		path: '/Financial/Sendto',
		redirect: '/Financial/Sendto',
		component: '/Financial/Sendto',
		name: '财务处理',
		Component: lazy(() => import('@/pages/Financial/Sendto')),
	},
	// TODO 纠错记录
	{
		path: '/CorrectionPage/Businessname',
		redirect: '/CorrectionPage/Businessname',
		component: '/CorrectionPage/Businessname',
		name: '纠错记录',
		Component: lazy(() => import('@/pages/CorrectionPage/Businessname')),
	},
	{
		path: '/CorrectionPage/Operating',
		redirect: '/CorrectionPage/Operating',
		component: '/CorrectionPage/Operating',
		name: '纠错审核',
		Component: lazy(() => import('@/pages/CorrectionPage/Operating')),
	},
	{
		path: '/CorrectionPage/BeRecord',
		redirect: '/CorrectionPage/BeRecord',
		component: '/CorrectionPage/BeRecord',
		name: '被纠错记录',
		Component: lazy(() => import('@/pages/CorrectionPage/BeRecord')),
	},
	// 达人管理
	{
		path: '/talentadmin/talentlist',
		redirect: '/talentlist',
		component: '/Talent/TalentList',
		name: '达人库',
		Component: lazy(() => import('@/pages/Talent/TalentList')),
	},
	// new
	{
		path: '/my/content',
		redirect: '/my/content',
		component: '/Start/content',
		name: '内容开放平台',
		Component: lazy(() => import('@/pages/Start/content')),
	},
	{
		path: '/my/contentDetails',
		redirect: '/my/contentDetails',
		component: '/Start/content/details',
		name: '内容开放平台详情',
		Component: lazy(() => import('@/pages/Start/content/details')),
	},
	// 质检页面
	// {
	// 	path: '/quality/operation',
	// 	redirect: '/quality/operation',
	// 	component: '/Quality/operation',
	// 	name: '运营质检',
	// Component: lazy(() => import('@/pages/Quality/operation')),
	// },
	// {
	// 	path: '/quality/official',
	// 	redirect: '/quality/official',
	// 	component: '/Quality/official',
	// 	name: '官方质检',
	// 	Component: QualityOfficial,
	// Component: lazy(() => import('@/pages/Quality/official')),
	// },
	// 运营
	{
		path: '/Convergence',
		redirect: '/Convergence',
		component: '/Operation/Convergence',
		name: '内容开放平台（运营）',
		Component: lazy(() => import('@/pages/Operation/Convergence')),
	},
	{
		path: '/reportedCheck',
		redirect: '/reportedCheck',
		component: '/Operation/reportedCheck',
		name: '二次提报审核',
		Component: lazy(() => import('@/pages/Operation/reportedCheck')),
	},
	// {
	// 	path: '/quality/spot',
	// 	redirect: '/quality/spot',
	// 	component: '/Quality/spot',
	// 	name: '官方抽检',
	// 	Component: QualitySpot,
	// Component: lazy(() => import('@/pages/Quality/spot')),
	// },
	// {
	// 	path: '/quality/Inspect',
	// 	redirect: '/quality/Inspect',
	// 	component: '/Quality/Inspect',
	// 	name: '主管送检',
	// 	Component: QualityInspect,
	// Component: lazy(() => import('@/pages/Quality/Inspect')),
	// },
	{
		path: '/thatPostCount',
		redirect: '/thatPostCount',
		component: '/thatPostCount',
		name: '在职人数设置',
		Component: lazy(() => import('@/pages/thatPostCount')),
	},
	// 人事管理
	{
		path: '/staff',
		redirect: '/staff',
		component: '/HrAdmin/Staff',
		name: '员工列表',
		Component: lazy(() => import('@/pages/HrAdmin/Staff')),
	},
	{
		path: '/dept',
		redirect: '/dept',
		component: '/HrAdmin/Dept',
		name: '部门管理',
		Component: lazy(() => import('@/pages/HrAdmin/Dept')),
	},
	// 商务管理
	{
		path: '/businessManagement/content',
		redirect: '/businessManagement/content',
		component: '/businessManagement/content',
		name: '内容开放平台',
		Component: lazy(() => import('@/pages/businessManagement/content')),
	},
	{
		path: '/businessManagement/vipWordSnatch',
		redirect: '/businessManagement/vipWordSnatch',
		component: '/businessManagement/vipWordSnatch',
		name: 'VIP达人',
		Component: lazy(() => import('@/pages/businessManagement/vipWordSnatch')),
	},
	{
		path: '/businessManagement/Sometao',
		redirect: '/businessManagement/Sometao',
		component: '/businessManagement/Sometao',
		name: '点淘VIP达人',
		Component: lazy(() => import('@/pages/businessManagement/Sometao')),
	},
	{
		path: '/businessManagement/vip',
		redirect: '/businessManagement/vip',
		component: '/businessManagement/vip',
		name: 'VIP商务管理界面',
		Component: lazy(() => import('@/pages/businessManagement/vip')),
	},
	// 点淘
	{
		path: '/mischief/content',
		redirect: '/mischief/content',
		component: '/mischief/content',
		name: '点淘内容开放平台',
		Component: lazy(() => import('@/pages/dt/content')),
	},
	{
		path: '/mischief/businessManagement',
		redirect: '/mischief/businessManagement',
		component: '/mischief/businessManagement',
		name: '点淘商务管理内容开放平台',
		Component: lazy(() => import('@/pages/dt/businessManagement')),
	},
	{
		path: '/mischief/Convergence',
		redirect: '/mischief/Convergence',
		component: '/mischief/Convergence',
		name: '点淘运营内容开放平台',
		Component: lazy(() => import('@/pages/dt/Convergence')),
	},
	{
		path: '/roles',
		redirect: '',
		component: '/Systeam/AdminRoles',
		name: '角色管理',
		Component: lazy(() => import('@/pages/Systeam/AdminRoles')),
	},
	{
		path: '/TagMerge',
		redirect: '/TagMerge',
		component: '/Systeam/TagMerge',
		name: '标签合并',
		Component: lazy(() => import('@/pages/Systeam/TagMerge')),
	},
	{
		path: '/poster/materialLibrary',
		redirect: '/poster/materialLibrary',
		component: 'poster/materialLibrary',
		name: '海报素材库',
		Component: lazy(() => import('@/pages/poster/materialLibrary')),
	},
	{
		path: '/poster/materialManagement',
		redirect: '/poster/materialManagement',
		component: 'poster/materialManagement',
		name: '海报素材管理',
		Component: lazy(() => import('@/pages/poster/materialManagement')),
	},
	{
		path: '/pdd/content',
		redirect: '/pdd/content',
		component: '/pdd/content',
		name: '拼多多内容开放平台',
		Component: lazy(() => import('@/pages/Pdd/content')),
	},
	{
		path: '/pdd/management',
		redirect: '/pdd/management',
		component: '/pdd/management',
		name: '拼多多内容开放平台_管理',
		Component: lazy(() => import('@/pages/Pdd/management')),
	},
	{
		path: '/pdd/operation',
		redirect: '/pdd/operation',
		component: '/pdd/operation',
		name: '拼多多内容开放平台_运营',
		Component: lazy(() => import('@/pages/Pdd/operation')),
	},
	{
		path: '/kuaishouStar/content',
		redirect: '/kuaishouStar/content',
		component: '/kuaishouStar/content',
		name: '快手-商务',
		Component: lazy(() => import('@/pages/ks/content')),
	},
	{
		path: '/kuaishouStar/Convergence',
		redirect: '/kuaishouStar/Convergence',
		component: '/kuaishouStar/Convergence',
		name: '快手-运营',
		Component: lazy(() => import('@/pages/ks/Convergence')),
	},
	{
		path: '/ks/KsManagement',
		redirect: '/ks/KsManagement',
		component: '/ks/KsManagement',
		name: '快手-管理',
		Component: lazy(() => import('@/pages/ks/management')),
	},
	{
		path: '/ks/KsLookDispatchYY',
		redirect: '/ks/KsLookDispatchYY',
		component: '/ks/KsLookDispatchYY',
		name: '查看发文-运营',
		Component: lazy(() => import('@/pages/ks/lookDispatch/convergence')),
	},
	{
		path: '/ks/KsLookManagementSW',
		redirect: '/ks/KsLookDispatchSW',
		component: '/ks/KsLookDispatchSW',
		name: '查看发文-商务',
		Component: lazy(() => import('@/pages/ks/lookDispatch/convergence')),
	},
	{
		path: '/weatherChannel/business',
		redirect: '/weatherChannel/business',
		component: '/weatherChannel/business',
		name: '微信视频号-商务',
		Component: lazy(() => import('@/pages/wechat/business')),
	},
	{
		path: '/weatherChannel/Convergence',
		redirect: '/weatherChannel/Convergence',
		component: '/weatherChannel/Convergence',
		name: '微信视频号-运营',
		Component: lazy(() => import('@/pages/wechat/Convergence')),
	},
	{
		path: '/contentData',
		redirect: '/contentData',
		component: '/contentData',
		name: '内容数据',
		Component: lazy(() => import('@/pages/contentData')),
	},
	{
		path: '/weatherChannel/manage',
		redirect: '/weatherChannel/manage',
		component: '/weatherChannel/manage',
		name: '微信视频号-管理',
		Component: lazy(() => import('@/pages/wechat/manage')),
	},
	{
		path: '/weatherChannel/dispatch',
		redirect: '/weatherChannel/dispatch',
		component: '/weatherChannel/dispatch',
		name: '微信视频号-查看发文',
		Component: lazy(() => import('@/pages/wechat/dispatch')),
	},
	{
		path: '/business/list',
		redirect: '/business/list',
		component: '/business/list',
		name: '商务列表',
		Component: lazy(() => import('@/pages/businessList')),
	},
	{
		path: '/qq/content',
		redirect: '/qq/content',
		component: '/qq/content',
		name: 'qq短视频-商务',
		Component: lazy(() => import('@/pages/QQ/content')),
	},
	{
		path: '/qq/management',
		redirect: '/qq/management',
		component: '/qq/management',
		name: 'qq短视频-管理',
		Component: lazy(() => import('@/pages/QQ/management')),
	},
	{
		path: '/qq/operation',
		redirect: '/qq/operation',
		component: '/qq/operation',
		name: 'qq短视频-运营',
		Component: lazy(() => import('@/pages/QQ/operation')),
	},
	{
		path: '/teamStar/sw',
		redirect: '/teamStar/sw',
		component: '/teamStar/sw',
		name: '商务-达人库',
		Component: lazy(() => import('@/pages/teamStar/business')),
	},
	{
		path: '/teamStar/swgl',
		redirect: '/teamStar/swgl',
		component: '/teamStar/swgl',
		name: '商务管理-达人库',
		Component: lazy(() => import('@/pages/teamStar/businessManagement')),
	},
	{
		path: '/gg/sw',
		redirect: '/gg/sw',
		component: '/gg/sw',
		name: '逛逛-商务',
		Component: lazy(() => import('@/pages/guangguang/content')),
	},
	{
		path: '/gg/swgl',
		redirect: '/gg/swgl',
		component: '/gg/swgl',
		name: '逛逛-商务管理',
		Component: lazy(() => import('@/pages/guangguang/businessManagement')),
	},
	{
		path: '/gg/yy',
		redirect: '/gg/yy',
		component: '/gg/yy',
		name: '逛逛-运营',
		Component: lazy(() => import('@/pages/guangguang/Convergence')),
	},
	{
		path: '/gg/lowFansSw',
		redirect: '/gg/lowFansSw',
		component: '/gg/lowFansSw',
		name: '逛逛低粉-商务',
		Component: lazy(() => import('@/pages/guangguang/lowFans/content')),
	},
	{
		path: '/gg/lowFansSwgl',
		redirect: '/gg/lowFansSwgl',
		component: '/gg/lowFansSwgl',
		name: '逛逛低粉-管理',
		Component: lazy(() => import('@/pages/guangguang/lowFans/businessManagement')),
	},
	{
		path: '/gg/lowFansYy',
		redirect: '/gg/lowFansYy',
		component: '/gg/lowFansYy',
		name: '逛逛低粉-运营',
		Component: lazy(() => import('@/pages/guangguang/lowFans/Convergence')),
	},
	{
		path: '/bjh/yy',
		redirect: '/bjh/yy',
		component: '/bjh/yy',
		name: '百家号-运营',
		Component: lazy(() => import('@/pages/bjh/Convergence')),
	},
	{
		path: '/bjh/sw',
		redirect: '/bjh/sw',
		component: '/bjh/sw',
		name: '百家号-商务',
		Component: lazy(() => import('@/pages/bjh/content')),
	},
	{
		path: '/bjh/swgl',
		redirect: '/bjh/swgl',
		component: '/bjh/swgl',
		name: '百家号-商务管理',
		Component: lazy(() => import('@/pages/bjh/businessManagement')),
	},

	{
		path: '/tbLive/sw',
		redirect: '/tbLive/sw',
		component: '/tbLive/sw',
		name: '淘宝直播-商务',
		Component: lazy(() => import('@/pages/taobao/live/content')),
	},

	{
		path: '/tbLive/swgl',
		redirect: '/tbLive/swgl',
		component: '/tbLive/swgl',
		name: '淘宝直播-管理',
		Component: lazy(() => import('@/pages/taobao/live/businessManagement')),
	},
	{
		path: '/tbLive/yy',
		redirect: '/tbLive/yy',
		component: '/tbLive/yy',
		name: '淘宝直播-运营',
		Component: lazy(() => import('@/pages/taobao/live/Convergence')),
	},
	{
		path: '/pageoptions',
		redirect: '/pageoptions',
		component: '/pageoptions',
		name: '页面操作配置',
		Component: lazy(() => import('@/components/PageOptions')),
	},
	{
		path: '/test',
		redirect: '/test',
		component: '/test',
		name: '测试Api专用',
		Component: lazy(() => import('@/pages/test')),
	},
	{
		path: '/fullScreen/tbLiveData/sw',
		redirect: '/fullScreen/tbLiveData/sw',
		component: '/fullScreen/tbLiveData/sw',
		name: '淘宝直播-数据报表商务',
		Component: lazy(() => import('@/pages/fullScreen/tbLiveData/sw')),
	},
	{
		path: '/fullScreen/tbLiveData/details',
		redirect: '/fullScreen/tbLiveData/details',
		component: '/fullScreen/tbLiveData/details',
		name: '淘宝直播-数据详情',
		Component: lazy(() => import('@/pages/fullScreen/tbLiveData/sw')),
	},
	{
		path: '/fullScreen/zfbData',
		redirect: '/fullScreen/zfbData',
		component: '/fullScreen/zfbData',
		name: '支付宝不达标列表',
		Component: lazy(() => import('@/pages/fullScreen/zfbData')),
	},
	{
		path: '/fullScreen/zfbStartData',
		redirect: '/fullScreen/zfbStartData',
		component: '/fullScreen/zfbStartData',
		name: '支付宝达人数据',
		Component: lazy(() => import('@/pages/fullScreen/zfbStartData')),
	},
	{
		path: '/jd/sw',
		redirect: '/jd/sw',
		component: '/jd/sw',
		name: '京东-商务',
		Component: lazy(() => import('@/pages/jd/content')),
	},
	{
		path: '/jd/swgl',
		redirect: '/jd/swgl',
		component: '/jd/swgl',
		name: '京东-商务管理',
		Component: lazy(() => import('@/pages/jd/businessManagement')),
	},
	{
		path: '/jd/yy',
		redirect: '/jd/yy',
		component: '/jd/yy',
		name: '京东-运营',
		Component: lazy(() => import('@/pages/jd/Convergence')),
	},

	{
		path: '/zfb/sw',
		redirect: '/zfb/sw',
		component: '/zfb/sw',
		name: '支付宝-商务',
		Component: lazy(() => import('@/pages/zfb/content')),
	},
	{
		path: '/zfb/swgl',
		redirect: '/zfb/swgl',
		component: '/zfb/swgl',
		name: '支付宝-商务管理',
		Component: lazy(() => import('@/pages/zfb/businessManagement')),
	},
	{
		path: '/zfb/yy',
		redirect: '/zfb/yy',
		component: '/zfb/yy',
		name: '支付宝-运营',
		Component: lazy(() => import('@/pages/zfb/Convergence')),
	},
	{
		path: '/externalVideo',
		redirect: '/externalVideo',
		component: '/externalVideo',
		name: '外站视频列表',
		Component: lazy(() => import('@/pages/editing/ExternalVideoList')),
	},
	{
		path: '/ggGhostwriter',
		redirect: '/ggGhostwriter',
		component: '/ggGhostwriter',
		name: '逛逛代发文',
		Component: lazy(() => import('@/pages/editing/ggGhostwriter')),
	},
	{
		path: '/ggOutgoingPage',
		redirect: '/ggOutgoingPage',
		component: '/ggOutgoingPage',
		name: '逛逛发文页',
		Component: lazy(() => import('@/pages/editing/ggOutgoingPage')),
	},
	{
		path: '/ggOutgoingList',
		redirect: '/ggOutgoingList',
		component: '/ggOutgoingList',
		name: '逛逛发文列表',
		Component: lazy(() => import('@/pages/editing/ggOutgoingList')),
	},
	{
		path: '/zfbAnalysis',
		redirect: '/zfbAnalysis',
		component: '/zfb/analysis',
		name: '支付宝数据',
		Component: lazy(() => import('@/pages/zfb/analysis')),
	},
	{
		path: '/msg/msgList',
		redirect: '/msg/msgList',
		component: '/businessMsg/msgList',
		name: '通知',
		Component: lazy(() => import('@/pages/businessMsg/msgList')),
	},
	{
		path: '/msg/managementMsg',
		redirect: '/msg/managementMsg',
		component: '/businessMsg/managementMsg',
		name: '通知管理',
		Component: lazy(() => import('@/pages/businessMsg/managementMsg')),
	},
	{
		path: '/msg/editMsg',
		redirect: '/msg/editMsg',
		component: '/businessMsg/editMsg',
		name: '编辑通知',
		Component: lazy(() => import('@/pages/businessMsg/editMsg')),
	},
	{
		path: '/msg/lookMsg',
		redirect: '/msg/lookMsg',
		component: '/businessMsg/lookMsg',
		name: '查看通知',
		Component: lazy(() => import('@/pages/businessMsg/lookMsg')),
	},
	{
		path: '/dispatch/zfb',
		redirect: '/dispatch/zfb',
		component: '/dispatch/zfb',
		name: '支付宝发文',
		Component: lazy(() => import('@/pages/dispatch/zfb')),
	},
	{
		path: '/dispatch/gg',
		redirect: '/dispatch/gg',
		component: '/dispatch/gg',
		name: '逛逛发文',
		Component: lazy(() => import('@/pages/dispatch/gg')),
	},
	{
		path: '/ggAnalysis',
		redirect: '/ggAnalysis',
		component: '/guangguang/analysis',
		name: '逛逛数据',
		Component: lazy(() => import('@/pages/guangguang/analysis')),
	},
	{
		path: '/ggSettleAccounts',
		redirect: '/ggSettleAccounts',
		component: '/guangguang/settleAccountsData',
		name: '逛逛结算数据',
		Component: lazy(() => import('@/pages/guangguang/settleAccountsData')),
	},
	{
		path: '/CateManagement',
		redirect: '/CateManagement',
		component: '/editing/CateManagement',
		name: '品类管理',
		Component: lazy(() => import('@/pages/editing/CateManagement')),
	},
	{
		path: '/ggEraDispatch',
		redirect: '/ggEraDispatch',
		component: '/editing/ggEraDispatch',
		name: '逛逛代发文',
		Component: lazy(() => import('@/pages/editing/ggEraDispatch')),
	},
	{
		path: '/GGJJMangement',
		redirect: '/GGJJMangement',
		component: '/editing/GGJJMangement',
		name: '逛逛剪辑管理',
		Component: lazy(() => import('@/pages/editing/GGJJMangement')),
	},
	{
		path: '/PublicationList',
		redirect: '/PublicationList',
		component: '/editing/PublicationList',
		name: '发文列表',
		Component: lazy(() => import('@/pages/editing/PublicationList')),
	},
	{
		path: '/gg/starCheckSw',
		redirect: '/gg/starCheckSw',
		component: '/guangguang/starCheck/sw',
		name: '逛逛-达人审核商务',
		Component: lazy(() => import('@/pages/guangguang/starCheck/sw')),
	},
	{
		path: '/gg/starCheckGl',
		redirect: '/gg/starCheckGl',
		component: '/guangguang/starCheck/gl',
		name: '逛逛-达人审核管理',
		Component: lazy(() => import('@/pages/guangguang/starCheck/gl')),
	},
	{
		path: '/gg/auditDataPanel',
		redirect: '/gg/auditDataPanel',
		component: '/guangguang/auditDataPanel',
		name: '逛逛审核数据面板',
		Component: lazy(() => import('@/pages/guangguang/auditDataPanel')),
	},
	{
		path: '/gg/settlementData',
		redirect: '/gg/settlementData',
		component: '/guangguang/settlementData',
		name: '逛逛结算导入数据',
		Component: lazy(() => import('@/pages/guangguang/settlementData/content')),
	},
	{
		path: '/gg/settlementData/operateContent',
		redirect: '/gg/settlementData/operateContent',
		component: '/guangguang/settlementData/operateContent',
		name: '逛逛结算导入数据-运营',
		Component: lazy(() => import('@/pages/guangguang/settlementData/operateContent')),
	},
	{
		path: '/zfbEraDispatch',
		redirect: '/zfbEraDispatch',
		component: '/zfb/editing/ggEraDispatch',
		name: '支付宝代发文',
		Component: lazy(() => import('@/pages/zfb/editing/ggEraDispatch')),
	},
	{
		path: '/zfbOutgoingPage',
		redirect: '/zfbOutgoingPage',
		component: '/zfb/editing/ggOutgoingPage',
		name: '支付宝发文页',
		Component: lazy(() => import('@/pages/zfb/editing/ggOutgoingPage')),
	},
	{
		path: '/zfbOutgoingList',
		redirect: '/zfbOutgoingList',
		component: '/zfbOutgoingList',
		name: '支付宝代发文列表',
		Component: lazy(() => import('@/pages/zfb/editing/ggOutgoingList')),
	},
	{
		path: '/zfbPublicationList',
		redirect: '/zfbPublicationList',
		component: '/zfb/editing/PublicationList',
		name: '支付宝发文列表',
		Component: lazy(() => import('@/pages/zfb/editing/PublicationList')),
	},
	{
		path: '/zfbJJMangement',
		redirect: '/zfbJJMangement',
		component: '/zfb/editing/GGJJMangement',
		name: '支付宝剪辑管理',
		Component: lazy(() => import('@/pages/zfb/editing/GGJJMangement')),
	},
	{
		path: '/zfbCateManagement',
		redirect: '/zfbCateManagement',
		component: '/zfb/editing/CateManagement',
		name: '支付宝品类管理',
		Component: lazy(() => import('@/pages/zfb/editing/CateManagement')),
	},
	{
		path: '/gg/earnings',
		redirect: '/gg/earnings',
		component: '/guangguang/earnings',
		name: '逛逛收入明细',
		Component: lazy(() => import('@/pages/guangguang/earnings')),
	},
	{
		path: '/meituan/sw',
		redirect: '/meituan/sw',
		component: '/meituan/sw',
		name: '美团-商务',
		Component: lazy(() => import('@/pages/meituan/content')),
	},
	{
		path: '/meituan/swgl',
		redirect: '/meituan/swgl',
		component: '/meituan/swgl',
		name: '美团-商务管理',
		Component: lazy(() => import('@/pages/meituan/businessManagement')),
	},
	{
		path: '/meituan/yy',
		redirect: '/meituan/yy',
		component: '/meituan/yy',
		name: '美团-运营',
		Component: lazy(() => import('@/pages/meituan/Convergence')),
	},
	{
		path: '/gg/unbundle',
		redirect: '/gg/unbundle',
		component: '/gg/unbundle',
		name: '逛逛-解绑',
		Component: lazy(() => import('@/pages/guangguang/unbundle')),
	},
	{
		path: '/ksly/content',
		redirect: '/ksly/content',
		component: '/ksly/content',
		name: '快手燎原-商务',
		Component: lazy(() => import('@/pages/ks/ksly/content')),
	},
	{
		path: '/ksly/Convergence',
		redirect: '/ksly/Convergence',
		component: '/ksly/Convergence',
		name: '快手燎原-运营',
		Component: lazy(() => import('@/pages/ks/ksly/Convergence')),
	},
	{
		path: '/ksly/management',
		redirect: '/ksly/management',
		component: '/ksly/management',
		name: '快手燎原-管理',
		Component: lazy(() => import('@/pages/ks/ksly/management')),
	},
	{
		path: '/audit/yy',
		redirect: '/audit/yy',
		component: '/audit/yy',
		name: '结算抽查-运营',
		Component: lazy(() => import('@/pages/finance/audit/yy')),
	},
	{
		path: '/audit/cw',
		redirect: '/audit/cw',
		component: '/audit/cw',
		name: '结算抽查-财务',
		Component: lazy(() => import('@/pages/finance/audit/cw')),
	},
	{
		path: '/settle/cw',
		redirect: '/settle/cw',
		component: '/settle/cw',
		name: '结算确认-财务',
		Component: lazy(() => import('@/pages/finance/settle/cw')),
	},
	{
		path: '/settle/yy',
		redirect: '/settle/yy',
		component: '/settle/yy',
		name: '结算确认-运营',
		Component: lazy(() => import('@/pages/finance/settle/yy')),
	},
	{
		path: '/audit/sw',
		redirect: '/audit/sw',
		component: '/audit/sw',
		name: '结算抽查-商务',
		Component: lazy(() => import('@/pages/finance/audit/sw')),
	},
	{
		path: '/audit/swgl',
		redirect: '/audit/swgl',
		component: '/audit/swgl',
		name: '结算抽查-商务主管',
		Component: lazy(() => import('@/pages/finance/audit/swgl')),
	},
	{
		path: '/wechat/editingGl',
		redirect: '/wechat/editingGl',
		component: '/wechat/editingGl',
		name: '视频号-剪辑管理',
		Component: lazy(() => import('@/pages/wechat/editing/gl')),
	},
	{
		path: '/wechat/editingSw',
		redirect: '/wechat/editingSw',
		component: '/wechat/editingSw',
		name: '视频号-代发文',
		Component: lazy(() => import('@/pages/wechat/editing/sw')),
	},

	{
		path: '/migu/sw',
		redirect: '/migu/sw',
		component: '/migu/sw',
		name: '咪咕-商务',
		Component: lazy(() => import('@/pages/migu/content')),
	},
	{
		path: '/migu/swgl',
		redirect: '/migu/swgl',
		component: '/migu/swgl',
		name: '咪咕-商务管理',
		Component: lazy(() => import('@/pages/migu/businessManagement')),
	},
	{
		path: '/migu/yy',
		redirect: '/migu/yy',
		component: '/migu/yy',
		name: '咪咕-运营',
		Component: lazy(() => import('@/pages/migu/Convergence')),
	},
	{
		path: '/youku/sw',
		redirect: '/youku/sw',
		component: '/youku/sw',
		name: '优酷-商务',
		Component: lazy(() => import('@/pages/youku/content')),
	},
	{
		path: '/youku/swgl',
		redirect: '/youku/swgl',
		component: '/youku/swgl',
		name: '优酷-商务管理',
		Component: lazy(() => import('@/pages/youku/businessManagement')),
	},
	{
		path: '/youku/yy',
		redirect: '/youku/yy',
		component: '/youku/yy',
		name: '优酷-运营',
		Component: lazy(() => import('@/pages/youku/Convergence')),
	},
	{
		path: '/tbAppeal/sw',
		redirect: '/tbAppeal/sw',
		component: '/taobao/appeal/sw',
		name: '淘宝直播申诉-商务',
		Component: lazy(() => import('@/pages/taobao/appeal/sw')),
	},
	{
		path: '/tbAppeal/swgl',
		redirect: '/tbAppeal/swgl',
		component: '/taobao/appeal/swgl',
		name: '淘宝直播申诉-商务管理',
		Component: lazy(() => import('@/pages/taobao/appeal/swgl')),
	},
	{
		path: '/tbAppeal/yy',
		redirect: '/tbAppeal/yy',
		component: '/taobao/appeal/yy',
		name: '淘宝直播申诉-运营',
		Component: lazy(() => import('@/pages/taobao/appeal/yy')),
	},
	{
		path: '/tb/seeding',
		redirect: '/tb/seeding',
		component: '/taobao/seeding',
		name: '淘宝直播-跟播',
		Component: lazy(() => import('@/pages/taobao/seeding')),
	},
	{
		path: '/meituan/editingGl',
		redirect: '/meituan/editingGl',
		component: '/meituan/editingGl',
		name: '美团-剪辑管理',
		Component: lazy(() => import('@/pages/meituan/editing/gl')),
	},
	{
		path: '/meituan/editingSw',
		redirect: '/meituan/editingSw',
		component: '/meituan/editingSw',
		name: '美团-代发文',
		Component: lazy(() => import('@/pages/meituan/editing/sw')),
	},
	{
		path: '/meituanCateManagement',
		redirect: '/meituanCateManagement',
		component: '/meituan/editing/CateManagement',
		name: '美团品类管理',
		Component: lazy(() => import('@/pages/meituan/editing/CateManagement')),
	},
	{
		path: '/gg/reflection',
		redirect: '/gg/reflection',
		component: '/gg/reflection',
		name: '逛逛-自审',
		Component: lazy(() => import('@/pages/guangguang/reflection')),
	},
	{
		path: '/multiPlatform/audit',
		redirect: '/multiPlatform/audit',
		component: '/multiPlatform/audit',
		name: '多平台-审核',
		Component: lazy(() => import('@/pages/multiPlatform/audit')),
	},
	{
		path: '/multiPlatform/project',
		redirect: '/multiPlatform/project',
		component: '/multiPlatform/project',
		name: '多平台-项目',
		Component: lazy(() => import('@/pages/multiPlatform/project')),
	},
	{
		path: '/estimated',
		redirect: '/estimated',
		component: '/estimated',
		name: '预估收入报表',
		Component: lazy(() => import('@/pages/estimated')),
	},
	{
		path: '/jd/publicationList',
		redirect: '/jd/publicationList',
		component: '/jd/publicationList',
		name: '京东视频发文列表',
		Component: lazy(() => import('@/pages/jd/publicationList')),
	},
	{
		path: '/handoverManagement',
		redirect: '/handoverManagement',
		component: '/handoverManagement',
		name: '交接管理',
		Component: lazy(() => import('@/pages/handoverManagement')),
	},
	{
		path: '/jd/clipManagement/sw',
		redirect: '/jd/clipManagement/sw',
		component: '/jd/clipManagement/sw',
		name: '京东工作台',
		Component: lazy(() => import('@/pages/jd/clipManagement/sw')),
	},
	{
		path: '/jd/clipManagement/gl',
		redirect: '/jd/clipManagement/gl',
		component: '/jd/clipManagement/gl',
		name: '京东剪辑管理',
		Component: lazy(() => import('@/pages/jd/clipManagement/gl')),
	},
	{
		path: '/jd/projectRelease',
		redirect: '/jd/projectRelease',
		component: '/jd/projectRelease',
		name: '京东发文审核',
		Component: lazy(() => import('@/pages/jd/projectRelease')),
	},
	{
		path: '/jd/projectReleaseVideo',
		redirect: '/jd/projectReleaseVideo',
		component: '/jd/projectReleaseVideo',
		name: '京东发文审核视频列表',
		Component: lazy(() => import('@/pages/jd/projectReleaseVideo')),
	},
	{
		path: '/keywordList',
		redirect: '/keywordList',
		component: '/keywordList',
		name: '关键词列表',
		Component: lazy(() => import('@/pages/keywordList')),
	},
	{
		path: '/documentUpload',
		redirect: '/documentUpload',
		component: '/documentUpload',
		name: '视频上传',
		Component: lazy(() => import('@/pages/documentUpload')),
	},
	{
		path: '/materialUpload',
		redirect: '/materialUpload',
		component: '/materialUpload',
		name: '素材上传',
		Component: lazy(() => import('@/pages/documentUpload/materialUpload')),
	},
	{
		path: '/brandManagement',
		redirect: '/brandManagement',
		component: '/brandManagement',
		name: '品牌管理',
		Component: lazy(() => import('@/pages/brandManagement')),
	},
	{
		path: '/brandGoodsLibrary',
		redirect: '/brandGoodsLibrary',
		component: '/brandGoodsLibrary',
		name: '品牌商品库',
		Component: lazy(() => import('@/pages/brandGoodsLibrary')),
	},
	{
		path: '/brandLibrary',
		redirect: '/brandLibrary',
		component: '/brandLibrary',
		name: '品牌店库',
		Component: lazy(() => import('@/pages/brandLibrary')),
	},
	{
		path: '/brandPost/materialList',
		redirect: '/brandPost/materialList',
		component: '/brandPost/materialList',
		name: '素材列表',
		Component: lazy(() => import('@/pages/brandPostMaterialList')),
	},
	{
		path: '/brandPost/videoList',
		redirect: '/brandPost/videoList',
		component: '/brandPost/videoList',
		name: '作品列表',
		Component: lazy(() => import('@/pages/brandPostVideoList')),
	},
	{
		path: '/brandStoreSkuList',
		redirect: '/brandStoreSkuList',
		component: '/brandStoreSkuList',
		name: '品牌店商品列表',
		Component: lazy(() => import('@/pages/brandStoreSkuList')),
	},
	{
		path: '/brandStoreAccountList',
		redirect: '/brandStoreAccountList',
		component: '/brandStoreAccountList',
		name: '品牌店发文账号列表',
		Component: lazy(() => import('@/pages/brandStoreAccountList')),
	},
	{
		path: '/videoAuditExamine',
		redirect: '/videoAuditExamine',
		component: '/videoAuditExamine',
		name: '作品审核',
		Component: lazy(() => import('@/pages/videoAuditExamine')),
	},
	{
		path: '/materialListAudit',
		redirect: '/materialListAudit',
		component: '/materialListAudit',
		name: '素材审核列表',
		Component: lazy(() => import('@/pages/materialListAudit')),
	},
	{
		path: '/materialListAuditCheck',
		redirect: '/materialListAuditCheck',
		component: '/materialListAuditCheck',
		name: '素材审核抽查列表',
		Component: lazy(() => import('@/pages/materialListAuditCheck')),
	},
	{
		path: '/brandCategory',
		redirect: '/brandCategory',
		component: '/brandCategory',
		name: '品牌品类列表',
		Component: lazy(() => import('@/pages/brandCategory')),
	},
	{
		path: '/brandIncome',
		redirect: '/brandIncome',
		component: '/brandIncome',
		name: '品牌收入',
		Component: lazy(() => import('@/pages/brandIncome')),
	},
	{
		path: '/brandIncomeInfo',
		redirect: '/brandIncomeInfo',
		component: '/brandIncomeInfo',
		name: '品牌收入明细',
		Component: lazy(() => import('@/pages/brandIncomeInfo')),
	},
	{
		path: '/brandListWorks',
		redirect: '/brandListWorks',
		component: '/brandListWorks',
		name: '品牌明细作品列表',
		Component: lazy(() => import('@/pages/brandListWorks')),
	},
	{
		path: '/starMark',
		redirect: '/starMark',
		component: '/starMark',
		name: '达人标记',
		Component: lazy(() => import('@/pages/starMark')),
	},
	{
		path: '/starMarksRecheck',
		redirect: '/starMarksRecheck',
		component: '/starMarksRecheck',
		name: '达人标记复核列表',
		Component: lazy(() => import('@/pages/starMarksRecheck')),
	},
	{
		path: '/starMarksRecord',
		redirect: '/starMarksRecord',
		component: '/starMarksRecord',
		name: '达人标记记录列表',
		Component: lazy(() => import('@/pages/starMarksRecord')),
	},
	{
		path: '/playletVideoCheck',
		redirect: '/playletVideoCheck',
		component: '/playletVideoCheck',
		name: '短剧视频审核',
		Component: lazy(() => import('@/pages/playletVideoCheck')),
	}
]
routers = routers.map((v: any) => {
	if (v.Component) {
		v.Component = Kidnap(v.Component)
	}
	return v
})

export default routers
