/**
 * @module 路由跳转
 */
import { pubCustomEvent, isArr } from '@/utils/tools'
import { getStore, setStore } from '@/utils/store'

// 递归 获取当前的路由数据
export const deepRouter = component => {
	const stroeRouter = JSON.parse(getStore('routerData') || '') ?? []
	let info = null
	const Foreachs = (arr = []) => {
		arr.forEach((item: any) => {
			// 如果url和key相等 获取
			if (item?.component == component) {
				info = item
			} else {
				isArr(item?.children) && info == null && Foreachs(item?.children)
			}
		})
		return info
	}
	return Foreachs(stroeRouter) ?? '/404'
}

/**
 * @function                      路由跳转方法
 * @param   {string}  component   需要跳转的路由 就是在路由页面定义的component
 * @param   {any}     state       需要携带的参数 跳转之后在props中获取state
 */

export default (component: string, state: any, name?: string) => {
	// 获取需要跳转的页面 (本地数据)
	let _router: any = deepRouter(component)
	let routerTabArr = JSON.parse(getStore('routerTabArr') || '') || []
	let flag = routerTabArr.every(v => v.component != component)
	if (flag) {
		// 当前列表没有 添加
		const home = routerTabArr[0]
		routerTabArr.splice(0, 1)
		routerTabArr.unshift({
			url: _router?.path,
			name: (name ? (name + '-') : '') + _router?.label,
			component: component,
			newState: state,
			icon: _router?.icon,
		})
		routerTabArr.unshift(home)
	} else {
		// 动态修改标签名
		routerTabArr = routerTabArr.map((v: any) => {
			if (v.component == component) {
				v.name = (name ? (name + '-') : '') + _router?.label
			}
			return v
		})
	}
	pubCustomEvent({ routerTabArr }, 'routerTab')
	// 发送当前选中的数据key
	pubCustomEvent(
		{ key: component, url: _router?.path, name: (name ? (name + '-') : '') + _router?.label, state, flag },
		'routerTabKey'
	)
	setStore('routerTabArr', JSON.stringify(routerTabArr))
}
