/**
 * @module 请求
 */

import axios, { AxiosResponse } from 'axios'
import { message, Modal } from 'antd'
import qs from 'qs'
import { getStore, clearStore } from './store'
import sign from '../config/sign'
import urlConfig from '../config/urlConfig'
import { isDevOrProEnv, isAppType } from '../config'

let baseUrl = urlConfig[isDevOrProEnv()]['requestUrl']

interface IData {
	app_type: string
	access_token: string
	app_id: number
	[x: string]: any
}
// type NonNullable<T> = T extends null | undefined ? never : T

interface IReqData {
	result?: any
	data?: any
	[s: string]: any
}

export function req<T>(
	url: string,
	data: T,
	callback: <V extends IReqData, K>(val: V, isFlag: boolean, msg: K) => void,
	methods: any = 'POST',
	key?: string
) {
	let _data: IData = {
		app_type: isAppType(),
		app_id: 1,
		access_token: getStore('access_token') || '',
	}
	let header = {
		'content-type': 'application/x-www-form-urlencoded',
	}
	let datasd = sign({ ..._data, ...data })
	baseUrl = urlConfig[isDevOrProEnv()][key ? key : 'requestUrl']
	axios({
		method: methods,
		url: `${baseUrl}${urlConfig.apiUrl[url]}`,
		data: qs.stringify(datasd),
		headers: header,
	})
		.then((response: AxiosResponse) => {
			if (response.data.code === 1002) {
				message.warning('登录超时，请重新登录!')
				clearStore()
				setTimeout(() => {
					window.location.replace('/login')
				}, 1500)
				return
			}
			let msg = ''
			if (response.data.code === 1007) {
				msg = '暂无权限!'
			} else {
				msg = response.data.message
			}
			callback && callback(response?.data, response.data.code === 1000, msg)
		})
		.catch(error => {
			console.log(error)
			callback({}, false, '网络不可用，请检查你的网络设置')
		})
}
// 请求其他域名下的接口
export function reqDmian<T extends IData>(
	url: string,
	data: T,
	callback: Function,
	key: string | 'vipKey' = 'vipKey',
	methods: any = 'POST'
) {
	if (!data.access_token) data.access_token = getStore('access_token') || ''
	if (!data.app_type) data.app_type = isAppType()
	let header = {
		'content-type': 'application/x-www-form-urlencoded',
	}
	let datasd = sign(data, key)
	axios({
		method: methods,
		url: `${urlConfig[isDevOrProEnv()]['vipUrl']}${urlConfig.apiUrl[url]}`,
		data: qs.stringify(datasd),
		headers: header,
	})
		.then(response => {
			if (response.data.code === 1002) {
				message.warning('您暂时没有权限哦~')
				return
			}
			let msg = ''
			if (response.data.code === 1007) {
				msg = '暂无权限!'
			} else {
				msg = response.data.message
			}
			callback(response.data, response.data.code === 1000, msg)
		})
		.catch(error => {
			console.log(error)
			callback({}, false, '网络不可用，请检查你的网络设置')
		})
}
export function reqConfirm(
	url: string,
	data: any,
	callback: <V extends IReqData, K>(val: V, isFlag: boolean, msg: K) => void,
	fn?: Function | undefined
) {
	Modal.confirm({
		title: '是否确认此操作?',
		content: '此操作不可逆，请谨慎操作',
		onOk: () => {
			typeof fn == 'function' && fn()
			req(url, data, (res, isFalg, msg) => {
				callback(res, isFalg, msg)
			})
		},
		onCancel: close => {
			close()
			// callback({}, false, '已取消')
		},
	})
}

// 上传接口 type=0=图片 type=1=视频 type=2=文件
export const uploadFile = (files: any, type: any = 0, successFun: Function) => {
	let url = `${baseUrl}/admin/public/upload`
	let formdata = new FormData()
	let data: any = {
		app_id: 1,
	}
	data.access_token = getStore('access_token') || ''
	data.app_type = isAppType()
	data = sign(data)
	formdata.append('app_id', data.app_id)
	formdata.append('access_token', data.access_token)
	formdata.append('sign', data.sign)
	formdata.append('file', files)
	formdata.append('type', type)
	formdata.append('app_type', isAppType())
	axios
		.post(url, formdata, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(response => {
			if (response.data.code === 1000) {
				successFun(response.data.result)
			} else {
				successFun('', response.data.message)
			}
		})
		.catch(() => {
			successFun('', '网络请求失败，请检查当前网络!')
		})
}

interface IreqOptions {
	isAnewReq?: boolean
	anewReqUrl?: string
	isConfirm?: boolean
	resCode?: Array<string | number> | any
	isOptions?: boolean
	OptionCall: Function | any
	confirmMsg?: string | any
	confirmTitle?: string | any
	anewReqAddParams: any
}

// 请求进行操作的函数
export function reqAndOptions(
	url: string,
	data: any,
	callback: Function,
	reqOptions: IreqOptions = {
		isAnewReq: true, // 是否进行请求
		anewReqUrl: '', // 重新请求url，默认使用当前的请求url
		isConfirm: true, //  启用状态弹窗
		resCode: [], // 操作状态码
		isOptions: true, // 是否进行操作
		OptionCall: () => { }, // 需要进行操作的函数,
		confirmMsg: null, // 如果出现弹窗 显示的内容,
		confirmTitle: null, // 如果出现弹窗 显示的弹窗头部title
		anewReqAddParams: {}, // 如果重新请求添加的 额外添加的请求参数
	},
	methods: any = 'POST'
) {
	if (!data.access_token) data.access_token = getStore('access_token') || ''
	data.app_type = isAppType()
	let header = {
		'content-type': 'application/x-www-form-urlencoded',
	}
	let datasd = sign(data)
	axios({
		method: methods,
		url: `${baseUrl}${urlConfig.apiUrl[url]}`,
		data: qs.stringify(datasd),
		headers: header,
	})
		.then(response => {
			let { code } = response.data
			if (response.data.code === 1002) {
				message.warning('登录超时，请重新登录!')
				clearStore()
				setTimeout(() => {
					window.location.replace('/login')
				}, 1500)
				return
			}
			// debugger
			// 表示根据请求状态进行操作
			if (Object.keys(reqOptions) && reqOptions?.isOptions && reqOptions.resCode.includes(code)) {
				// 如果只是需要弹窗时候 并且是在有弹窗内容 操作函数的前提下
				if (
					reqOptions?.isConfirm &&
					reqOptions?.confirmMsg &&
					typeof reqOptions?.OptionCall == 'function'
				) {
					Modal.confirm({
						title: reqOptions?.confirmTitle ?? '',
						content: reqOptions?.confirmMsg ?? response.data.message,
						onOk: () => {
							reqOptions?.OptionCall(response.data)
						},
					})
					return
				}
				// 如果进行自定义操作函数
				if (typeof reqOptions?.OptionCall == 'function') {
					reqOptions?.OptionCall(response.data)
					return
				}
				// 如果需要弹窗 并且需要重新请求的时候
				if (reqOptions.isConfirm && reqOptions.isAnewReq) {
					try {
						Modal.confirm({
							title: reqOptions?.confirmTitle ?? '',
							content: reqOptions?.confirmMsg ?? response.data.message,
							onOk: () => {
								// 如果有操作函数 进行操作函数 如果没有进行默认的操作处理
								typeof reqOptions?.OptionCall == 'function'
									? reqOptions?.OptionCall(response.data)
									: req(
										reqOptions?.anewReqUrl ?? url,
										{ ...data, ...reqOptions?.anewReqAddParams },
										(res, isFalg, msg) => {
											callback(res, isFalg, msg)
										}
									)
							},
							onCancel: () => {
								// callback({}, false, '已取消')
							},
						})
					} catch (error) {
						console.log('err', error)
					}
				}
				return
			}
			let msg = ''
			if (response.data.code === 1007) {
				msg = '暂无权限!'
			} else {
				msg = response.data.message
			}
			callback(response.data, response.data.code === 1000, msg)
		})
		.catch(error => {
			console.log(error, 'asdasd')
			callback({}, false, '网络不可用，请检查你的网络设置')
		})
}
