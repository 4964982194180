import {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react'
import { Tree, Button, Modal, Form, Input, Radio, message, Select } from 'antd'
import Icon from '@/components/system/RouteIcon'
import AuthTreeSelect from '@/components/system/AuthChecked'
import routerComponents from '@/config/routerConfig'
import SelectIconModal from '@/components/Public/SelectIconModal'
import { req, reqConfirm } from '@/utils/request'
const { Option } = Select
import './index.less'

// 表单栅格样式
const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
}

// 路由渲染列
const RenderTreeNode = ({
	id,
	label,
	onAdd,
	pid,
	component,
	name,
	path,
	type,
	code,
	redirect,
	icon,
	onEdit,
	onDel,
	business,
}) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				height: 30,
			}}
		>
			<Icon type={icon} style={{ fontSize: 18, marginRight: 5 }} />
			<span style={{ marginRight: 'auto' }}>{label}</span>
			<Button
				onClick={() =>
					onAdd({
						label,
						id,
						pid,
						component,
						name,
						path,
						type,
						code,
						redirect: redirect,
						icon,
					})
				}
				size='small'
				type='primary'
				icon={<Icon type='PlusOutlined' />}
				style={{ marginRight: id === 0 ? 0 : 10, width: id === 0 ? 120 : 33.3 }}
			/>
			{id !== 0 && (
				<Button
					onClick={() =>
						onEdit({
							label,
							id,
							pid,
							component,
							name,
							path,
							type,
							code,
							redirect: redirect,
							icon,
							business,
						})
					}
					size='small'
					type='primary'
					icon={<Icon type='EditOutlined' />}
					style={{
						marginRight: 10,
						width: 33.3,
						background: '#f90',
						borderColor: '#f90',
					}}
				/>
			)}
			{id !== 0 && (
				<Button
					onClick={() => onDel(id)}
					size='small'
					type='primary'
					danger
					icon={<Icon type='CloseOutlined' />}
					style={{ width: 33.3 }}
				/>
			)}
		</div>
	)
}

// 增删改查弹框
const EditModal = forwardRef(
	(
		{
			editInfo, // 默认参数
			visible, // 弹框展示
			onChange, // 关闭弹框
		},
		ref
	) => {
		const [showSelectIcon, setShowSelectIcon] = useState(false) // 控制图标菜单弹框展示
		const [form] = Form.useForm()
		const [loading, setLoading] = useState(false)
		const [iconName, setIconName] = useState('EditOutlined')
		const authRef = useRef(null)
		// 暴露的方法
		useImperativeHandle(ref, () => ({
			setForm: e => {
				form.setFieldsValue({
					name: e.name,
					label: e.label,
					component: e.component,
					type: e.type,
					redirect: e.redirect,
					path: e.path,
					business: e.business,
				})
				authRef.current.onSet(e.code)
				setIconName(e.icon)
				setLoading(false)
			},
			resetForm: () => {
				form.setFieldsValue({
					name: '',
					label: '',
					component: '',
					type: '',
					redirect: '',
					path: '',
					business: 0,
				})
				setIconName('EditOutlined')
				if (authRef.current) authRef.current.onRest()
				setLoading(false)
			},
		}))

		// 点击提交
		const onSubmit = () => {
			form
				.validateFields()
				.then(values => {
					if (loading) return
					setLoading(true)
					let codeData = authRef.current.onGet()
					let submitCode = []
					let arrKey = Object.keys(codeData)
					arrKey.forEach(item => {
						if (codeData[item]) submitCode.push(...codeData[item])
					})
					reqConfirm(
						editInfo.id ? 'menuUpdate' : 'menuAdd',
						{
							...values,
							code: submitCode.join(','),
							icon: iconName,
							pid: editInfo.pid,
							id: editInfo.id,
						},
						(res, isFalg, msg) => {
							if (isFalg) {
								message.success(editInfo.id ? '保存成功！' : '创建成功！')
								onChange(true)
							} else {
								setLoading(false)
								if (msg !== '已取消') {
									message.warning(msg)
								}
							}
						}
					)
				})
				.catch(error => {
					setLoading(false)
				})
		}

		return (
			<Modal
				width={900}
				destroyOnClose
				forceRender={true}
				title={editInfo.id ? '编辑菜单' : '新增菜单'}
				visible={visible}
				footer={null}
				onCancel={() => onChange(false)}
			>
				<div className='arp_edit_modal' style={{ position: 'relative' }}>
					<SelectIconModal
						iconName={iconName}
						visible={showSelectIcon}
						onChange={e => {
							setShowSelectIcon(false)
							if (e) setIconName(e)
						}}
					/>
					{visible && (
						<Form form={form} {...layout} name='edit_menu_from'>
							{/* 路由标题 路由图标 */}
							<Form.Item
								label='路由标题'
								name='label'
								rules={[{ required: true, message: '请输入路由标题' }]}
							>
								<Input
									placeholder='请输入路由标题'
									addonAfter={
										<Icon
											type={iconName}
											onClick={() => setShowSelectIcon(true)}
										/>
									}
								/>
							</Form.Item>
							{/* 路由名 */}
							<Form.Item
								label='路由名'
								name='name'
								rules={[{ required: true, message: '请输入路由名' }]}
							>
								<Input placeholder='请输入路由名' maxLength={100} />
							</Form.Item>
							{/* 菜单类型 页面 按钮 */}
							<Form.Item label='菜单类型' name='type' required initialValue={1}>
								<Radio.Group>
									<Radio value={1}>菜单路由</Radio>
									<Radio value={2}>非菜单路由</Radio>
									<Radio value={3}>按钮</Radio>
								</Radio.Group>
							</Form.Item>
							{/* 路由地址 */}
							<Form.Item
								label='路由地址'
								name='path'
								rules={[{ required: true, message: '请输入路由地址' }]}
							>
								<Input placeholder='请输入路由地址' />
							</Form.Item>
							{/* 路由重定向 */}
							<Form.Item
								label='路由重定向'
								name='redirect'
								rules={[{ required: true, message: '请输入路由重定向' }]}
							>
								<Input placeholder='请输入路由重定向' />
							</Form.Item>
							{/* 组件地址 */}
							<Form.Item
								label='组件地址'
								name='component'
								rules={[{ required: true, message: '请输入组件地址' }]}
							>
								<Select placeholder='请选择组件地址'>
									{routerComponents.map(item => (
										<Option key={item.path} value={item.component}>
											{item.component}{' '}
											<span style={{ color: '#999', fontSize: 12 }}>
												({item.name})
											</span>
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='所属业务'
								name='business'
								// initialValue={0}
								rules={[{ required: true, message: '请选择所属业务' }]}
							>
								<Radio.Group defaultValue={0}>
									<Radio value={0}>全部</Radio>
									<Radio value={1}>oa</Radio>
									<Radio value={2}>ec</Radio>
									<Radio value={3}>品牌发文</Radio>
								</Radio.Group>
							</Form.Item>
						</Form>
					)}
					{/* 权限码 */}
					<Form.Item style={{ height: 'auto' }} label='权限码' {...layout}>
						{/* <Input placeholder="请输入权限码" /> */}
						<AuthTreeSelect ref={authRef} />
					</Form.Item>
				</div>
				<div className='admin_router_page_bottom card_background'>
					<Button
						loading={loading}
						type='primary'
						style={{ marginRight: 80 }}
						onClick={onSubmit}
					>
						提交
					</Button>
					<Button loading={loading} onClick={() => onChange(false)}>
						取消
					</Button>
				</div>
			</Modal>
		)
	}
)

const AdminRoutePage = () => {
	const [treeData, setTreeData] = useState([]) // 数据
	const [showEdit, setShowEdit] = useState(false) // 新增修改菜单栏弹框
	const [editInfo, setEditInfo] = useState({})
	const [oneShow, setOneShow] = useState(false)

	const refForm = useRef(null)
	// dom渲染完成周期
	useEffect(() => {
		// setTreeData()
		getData()
	}, [])

	// 获取数据
	const getData = () => {
		req('menuList', {}, (res, isFlag, msg) => {
			if (isFlag) {
				setTreeData([
					{
						label: '菜单路由管理',
						key: 0,
						id: 0,
						icon: 'SettingOutlined',
						children: res.result,
					},
				])
				setOneShow(true)
			} else {
				message.warning(msg)
			}
		})
	}

	// 删除
	const onDel = ids => {
		reqConfirm('menuDel', { ids }, (res, isFalg, msg) => {
			if (isFalg) {
				message.success('删除成功!')
				getData()
			} else {
				if (msg !== '已取消') {
					message.warning(msg)
				}
			}
		})
	}
	return (
		<div className='admin_route_page'>
			<EditModal
				ref={refForm}
				editInfo={editInfo}
				visible={showEdit}
				onChange={e => {
					if (e) getData()
					setShowEdit(false)
				}}
			/>
			{oneShow && (
				<Tree
					defaultExpandAll={true}
					draggable
					blockNode
					treeData={treeData}
					titleRender={props => (
						<RenderTreeNode
							onDel={onDel}
							onEdit={e => {
								refForm.current.setForm(e)
								setEditInfo(e)
								setShowEdit(true)
							}}
							onAdd={e => {
								refForm.current.resetForm()
								setEditInfo({ pid: e.id })
								setShowEdit(true)
							}}
							{...props}
						/>
					)}
				/>
			)}
		</div>
	)
}

export default AdminRoutePage
